import React, { ReactElement } from 'react'
import { Widget } from '@typeform/embed-react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { HeaderLight } from '../components/header'

const MentoringPage = (): ReactElement => {
	return (
		<Layout minimal header={<HeaderLight />}>
			<SEO />
			<Widget id="I4fnOxWQ" style={{ width: '100%', height: '100%' }} />
		</Layout>
	)
}

export default MentoringPage
